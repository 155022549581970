"use client";

import React from "react";
import { FourOFour } from "@gonoodle/gn-universe-ui";

import { useRouter } from "../hooks";
import AppBar from "../components/AppBar";
import config from "../config";

export default function FourOFourPage() {
  const router = useRouter();

  return (
    <>
      <AppBar />

      <FourOFour
        onBackPress={() => {
          router.replace(config.WEB_URL);
        }}
      />
    </>
  );
}
